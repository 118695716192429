<template>
  <div class="card">
    <div class="card-body px-lg-5 py-lg-5">
      <validation-observer ref="observer">
        <form role="form" @submit.prevent="sendResetPassword">
          <div class="text-left py-2 mb-4 clear_text">
            Enter your email address and we’ll
            send you a recovery link.
          </div>
          <div>
            <base-alert type="success" v-if="email_sent"
              ><strong
                >Account recovery email sent to {{ email }}</strong
              ></base-alert
            >
            <base-alert v-if="not_found" type="warning">
              <strong>{{ not_found }}</strong></base-alert
            >
            <base-alert type="danger" v-if="error">
              <strong
                >Something went wrong, Could not send recovery email.</strong
              ></base-alert
            >
          </div>
          <div v-if="!email_sent">
            <div class="form-group mb-3">
              <validation-provider
                name="Email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <base-input
                  name="email"
                  v-model="email"
                  placeholder="Email"
                  :error="errors[0]"
                ></base-input>
              </validation-provider>
            </div>
            <div class="text-center">
              <p>
                <router-link class="mylink" to="/login"
                  >Log In</router-link
                >
              </p>
            </div>
            <div class="text-center">
              <base-button
                @click="sendResetPassword()"
                :disabled="loadings.button"
                type="login"
                class="ntt btn-block"
              >
                <spinner
                  v-if="loadings.button"
                  type="grow"
                  class="spinner-grow-sm"
                ></spinner>
                <span v-else>Send Recovery Email</span></base-button
              >
            </div>
          </div>
          <input type="submit" style="display:none;" />
        </form>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import Spinner from "@/components/Spinner";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  components: {
    Spinner,
    ValidationProvider,
    ValidationObserver
  },
  data: function() {
    return {
      email: "",
      loadings: {
        button: false
      },
      email_sent: false,
      error: false,
      not_found: null
    };
  },
  methods: {
    sendResetPassword() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }
        this.loadings.button = true;
        this.email_sent = false;
        this.error = false;
        this.not_found = null;
        this.$axios
          .post("forgot-password/", { email: this.email })
          .then(res => {
            if (res) {
              this.email_sent = true;
            }
            this.loadings.button = false;
          })
          .catch(err => {
            this.loadings.button = false;
            if (err.response) {
              if (err.response.status == 404) {
                this.not_found = err.response.data.detail;
              }
            } else {
              this.error = true;
            }
          });
      });
    }
  }
};
</script>
<style></style>

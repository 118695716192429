<template>
  <div>
    <div class="">
      <div class="container">
        <div class="mt-2">
          <div class="row">
            <div class="mt-7 col-md-6 mx-auto">
              <div><forgot-password /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ForgotPassword from "@/components/ForgotPassword";
export default {
  components: {
    ForgotPassword
  },
  data: function() {
    return {};
  },
  metaInfo: {
    title: "Reset Password"
  }
};
</script>
<style></style>
